@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.header-pro {
  width: 100%;
  background-color: var(--c_primary);

  .header-bg {
    position: relative;
    width: 100%;

    video,
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      min-width: 100%;
      height: 100vh;
      min-height: 100vh;
      object-fit: cover;
    }

    video {
      z-index: 2;
    }

    img {
      z-index: 1;
    }
  }

  .header-content {
    display: flex;
    align-items: center;
    color: var(--c_white);
    text-align: center;

    .swiper-container {
      cursor: default;
      width: 100%;
      overflow: visible;

      .swiper-slide {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .swiper-pagination {
        position: initial;
        transform: translateY(-40px);
        opacity: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        animation: defaultAnimation 0.7s ease 3.4s forwards;

        .swiper-pagination-bullet {
          opacity: 1;
          width: 4px;
          height: 4px;
          background: var(--c_grey_100);
        }

        .swiper-pagination-bullet-active {
          width: 10px;
          height: 10px;
          border: 2px solid var(--c_grey_100);
          background: transparent;
        }
      }
    }

    .t-label {
      transform: translateY(-30px);
      opacity: 0;
      max-width: 1000px;
      margin-bottom: 40px;
      animation: defaultAnimation 0.7s ease 2.8s forwards;
    }

    .t-hero {
      transform: translateY(-40px);
      opacity: 0;
      max-width: 1000px;
      animation: defaultAnimation 0.7s ease 3.1s forwards;
    }
  }
}

@keyframes defaultAnimation {
  0% {}

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@include commonTabletMobile {
  .header-pro {
    width: 100%;

    .header-bg {
      display: none;
    }

    .header-content {
      flex-direction: column;
      padding: 60px 0;
      background-repeat: no-repeat;
      background-position: top;
      background-attachment: fixed;

      .t-label,
      .t-hero {
        padding: 0 20px;
      }

      .header-pro-links {
        transform: translateY(-30px);
        opacity: 0;
        margin-top: 60px;
        animation: defaultAnimation 0.7s ease 1.6s forwards;

        .login-pro {
          @include icon(user_rounded, before) {
            margin-right: 8px;
            font-size: 26px;
          }
          margin-right: 24px;
          color: var(--c_white);
        }
      }
    }
  }
}
