@use 'header_pro_commons' as *;
@use '../../shared/elements/embedded_video/embedded_video';

@include desktopStyles {
  .header-pro {
    width: 100%;
    height: 100vh;

    .header-bg {
      height: 100vh;
      overflow: hidden;
    }

    .header-content {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      z-index: 300;
      transform: translateY(-50%);
      background-image: none !important;

      .swiper-container {
        .swiper-pagination {
          position: absolute;
          left: 0;
          bottom: -40px;
          right: 0;
        }
      }

      .header-pro-links {
        display: none;
      }
    }
  }
}
